<template>
  <BaseLayout wrapperClass="paymentInfo">
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <SidebarLinks :sidebarData="sidebarData" />
          </b-col>
          <b-col lg="9">
            <h1>{{ $t("payment_cancel") }}</h1>
            <div v-if="transactionId != null">
              {{ $t("transaction") }}: {{ "#" + transactionId }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_wieleroutfits/core/components/BaseLayout";
import SidebarLinks from "@/base/core/components/SidebarLinks";

export default {
  name: "PaymentSuccess",
  components: {
    BaseLayout,
    SidebarLinks,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_cancel") },
        { name: "keywords", content: this.$t("payment_cancel") },
        { name: "description", content: this.$t("payment_cancel") },
      ],
      title: this.$t("payment_cancel"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
  },
  mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    this.$router.push({ name: "checkout" });
  },

  data: () => ({}),
};
</script>

